import myPublic from '@/utils/public.js';

export default {
    computed: {
        //项目分级
        initProjectGrade: () => (detail) => {
            let project_grade = Number(detail.project_grade);
            let text = '';
            switch (project_grade) {
                case 1:
                    return '申报待审';
                case 2:
                    text = '不予支持';
                    if (detail.project_grade_reason) {
                        text = text + `（意见：${detail.project_grade_reason}）`
                    }
                    return text;
                case 3:
                    text = '进一步完善';
                    if (detail.project_grade_reason) {
                        text = text + `（意见：${detail.project_grade_reason}）`
                    }
                    return text;
                case 4:
                    text = '开展前期工作';
                    let arr= [];
                    let text_arr = []
                    if(detail.project_grade_choose){
                        arr = detail.project_grade_choose.split(',')
                        arr.forEach(item=>{
                            if(item==1){
                                text_arr.push('可研报告评审')
                            }
                            if(item==2){
                                text_arr.push('地勘')
                            }
                            if(item==3){
                                text_arr.push('环境评测')
                            }
                        })
                        if(text_arr.length>0){
                            text = text + `（${text_arr.join('、')}）`
                        }
                    }
                    return text;
            }
        },
        //项目类别
        initProjectGenre: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '保障和改善民生';
                case 2:
                    return '文化教育支援';
                case 3:
                    return '产业支援促进就业';
                case 4:
                    return '智力支援';
                case 5:
                    return '交往交流交融';
                case 6:
                    return '保障和改善民生';
            }
        },
        //项目性质
        initProjectQuality: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '固定资产投资类';
                case 2:
                    return '非固定资产投资类';
                case 3:
                    return '其他';
            }
        },
        //拨付类型
        initPaymentType: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '工程款';
                case 2:
                    return '质保金';
                case 3:
                    return '二类费用';
                case 4:
                    return '其他';
            }
        },
        //项目审计,检查,评估
        initAuditCheckAssess: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '项目审计';
                case 2:
                    return '项目检查';
                case 3:
                    return '项目评估';
            }
        },
        //项目实施情况（进度）
        initImplementationRate: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return 20;
                case 2:
                    return 50;
                case 3:
                    return 80;
                case 4:
                    return 100;
                case 5:
                    return 0;
            }
        },
        //建设性质
        initBuildQuality: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '新建';
                case 2:
                    return '续建';
                case 3:
                    return '其它';
                case 4:
                    return '完工未竣工';
                case 5:
                    return '跨年';
            }
        },
        //项目状态
        initProjectStatus: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '项目未开始实施';
                case 2:
                    return '前期准备过程中';
                case 3:
                    return '项目开始实施';
                case 4:
                    return '项目进程过半';
                case 5:
                    return '完工未验收';
                case 6:
                    return '竣工验收';
                case 7:
                    return '终止项目';
                case 8:
                    return '续建项目';
                case 9:
                    return '变更项目';
            }
        },
        //项目实施情况（前期）
        initImplementationBefore: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '批复/下达';
                case 2:
                    return '招投标/政府采购';
                case 3:
                    return '开工';
            }
        },
        //项目实施情况（后期）
        initImplementationAfter: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '完工';
                case 2:
                    return '验收';
                case 3:
                    return '审计';
            }
        },
        //项目管理附件title字段
        initFileList: () => (e) => {
            switch (e) {
                case 'before':
                    return '前期';
                case 'process':
                    return '过程';
                case 'check':
                    return '总结与验收';
                case 'monthly':
                    return '月报及总结';
                case 'log':
                    return '项目管理日志';
            }
        },
        /*计划外项目*/
        //投入来源
        initSource: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '财政资金';
                case 2:
                    return '社会组织';
                case 3:
                    return '个人';
                case 4:
                    return '企业资金';
            }
        },
        //项目类别
        initProjectType: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '区县';
                case 2:
                    return '街镇';
                case 3:
                    return '村村';
                case 4:
                    return '村企';
                case 5:
                    return '其他';
            }
        },
        initArea: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '州本级';
                case 2:
                    return '玛沁县';
                case 3:
                    return '久治县';
                case 4:
                    return '班玛县';
                case 5:
                    return '甘德县';
                case 6:
                    return '达日县';
                case 7:
                    return '玛多县';
            }
        },
        //任务类型
        initTaskType: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '项目类';
                case 2:
                    return '党建类';
                case 3:
                    return '日常管理类';
            }
        },
        //循环方式回显
        initShowRound: () => (round) => {
            if (round) {
                let new_round;
                if (myPublic.isJsonString(round)) {
                    new_round = JSON.parse(round);
                } else {
                    new_round = round;
                }
                if (new_round.round_type === 'week' && !isNaN(Number(new_round.child.day_of_week))) {
                    let week = '';
                    switch (Number(new_round.child.day_of_week)) {
                        case 0:
                            week = '周日';
                            break;
                        case 1:
                            week = '周一';
                            break;
                        case 2:
                            week = '周二';
                            break;
                        case 3:
                            week = '周三';
                            break;
                        case 4:
                            week = '周四';
                            break;
                        case 5:
                            week = '周五';
                            break;
                        case 6:
                            week = '周六';
                            break;
                    }
                    return `每${week}循环上报`;
                }
                if (new_round.round_type === 'month' && !isNaN(Number(new_round.child.day_of_month)) && new_round.child.type) {
                    if (new_round.child.type === 'every') {
                        return `每月第${Number(new_round.child.day_of_month)}日循环上报`;
                    }
                    if (new_round.child.type === 'single') {
                        return `每单月第${Number(new_round.child.day_of_month)}日循环上报`;
                    }
                    if (new_round.child.type === 'double') {
                        return `每双月第${Number(new_round.child.day_of_month)}日循环上报`;
                    }
                }
                if (new_round.round_type === 'quarter' && new_round.child.date) {
                    return `自${new_round.child.date}开始每三个月循环上报`;
                }
                if (new_round.round_type === 'once' && new_round.child.date_time) {
                    return `${new_round.child.date_time}上报 且只上报一次`;
                }
            }
        },
        //任务评价回显
        initTaskAppraise: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return {text: '优秀', color: '#ff2d2d'};
                case 2:
                    return {text: '良好', color: '#fe842b'};
                case 3:
                    return {text: '合格', color: '#3db00a'};
                case 4:
                    return {text: '不合格', color: '#666666'};
            }
        },
        //任务评价img回显
        initTaskAppraiseImg: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return require('@/assets/img/task_appraise_1.png');
                case 2:
                    return require('@/assets/img/task_appraise_2.png');
                case 3:
                    return require('@/assets/img/task_appraise_3.png');
                case 4:
                    return require('@/assets/img/task_appraise_4.png');
            }
        },
        //任务上报评价回显
        initReportType: () => (e) => {
            if (e == 1) {
                return {text: '提前完成', color: '#4a84fb'};
            } else if (e == 2) {
                return {text: '按时完成', color: '#34c30d'};
            } else if (e == 3) {
                return {text: '延期完成', color: '#f85451'};
            } else if (e == 4) {
                return {text: '未完成', color: '#cccccc'};
            } else {
                return {text: '待评价'};
            }
        },
        //项目变更情况
        initIsChange: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '未变更';
                case 2:
                    return '已变更';
            }
        },
        //任务属性回显
        initTaskQuality: () => (e) => {
            switch (Number(e)) {
                case 1:
                    return '常规性';
                case 2:
                    return '临时性';
            }
        },
    }
};